.footer {
  margin-bottom: 1em;
}

.title {
  font-size: 1.1rem;
  font-weight: 600;
  border-color: transparent;
  line-height: 1.25;
  text-overflow: ellipsis;
  max-width: 100%;
  display: block;
  overflow: hidden;
}

.links {
  text-align: center;
  clear: both;
  margin-bottom: 12px;
  display: flex;
  flex-wrap: wrap;
}

.link {
  display: flex !important;
  flex: 1 0 45%;
  padding: 1rem;

  a {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    white-space: normal;
    padding: 15px 20px;
    display: block;
    width: 100%;
    position: relative;
    -webkit-transition: none;
    transition: none;
    -webkit-transition: color .25s cubic-bezier(.08, .59, .29, .99), border-color .25s cubic-bezier(.08, .59, .29, .99), background-color .25s cubic-bezier(.08, .59, .29, .99), -webkit-box-shadow .25s cubic-bezier(.08, .59, .29, .99), -webkit-transform .25s cubic-bezier(.08, .59, .29, .99);
    transition: color .25s cubic-bezier(.08, .59, .29, .99), border-color .25s cubic-bezier(.08, .59, .29, .99), background-color .25s cubic-bezier(.08, .59, .29, .99), -webkit-box-shadow .25s cubic-bezier(.08, .59, .29, .99), -webkit-transform .25s cubic-bezier(.08, .59, .29, .99);
    transition: box-shadow .25s cubic-bezier(.08, .59, .29, .99), color .25s cubic-bezier(.08, .59, .29, .99), border-color .25s cubic-bezier(.08, .59, .29, .99), transform .25s cubic-bezier(.08, .59, .29, .99), background-color .25s cubic-bezier(.08, .59, .29, .99);
    transition: box-shadow .25s cubic-bezier(.08, .59, .29, .99), color .25s cubic-bezier(.08, .59, .29, .99), border-color .25s cubic-bezier(.08, .59, .29, .99), transform .25s cubic-bezier(.08, .59, .29, .99), background-color .25s cubic-bezier(.08, .59, .29, .99), -webkit-box-shadow .25s cubic-bezier(.08, .59, .29, .99), -webkit-transform .25s cubic-bezier(.08, .59, .29, .99);
  }
}

.linkIcon {
  padding: 15px 66px!important
}

.iconWrap {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 46px;
  width: 46px;
  top: 50%;
  left: 4px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  pointer-events: none;
  z-index: 5;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}

.btn {
  position: relative;
  outline: 0!important;
  font-weight: 700;
  border-radius: 0;
  text-decoration: none;
  display: inline-block;
  line-height: 1.5;
}

.btnLink {
  color: #263238;
  border-radius: 32px;
  background-color: #fff;
  -webkit-box-shadow: 0 8px 12px 0 rgba(0, 0, 0, .16);
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, .16);

  &:hover {
    text-decoration: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.address {
  color: white;
  font-size: 0.925em;
  text-decoration: none;
}