.container {
  max-width: 576px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;

  @media (min-width:768px) {
    max-width: 720px;
  }
}