$primary: #EF4492;

.container {
  padding-top: 0.5em;
}

.productList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  background-image: url('/images/background.png');
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 3rem 2rem 2rem 2rem;
  outline: none;
  border-radius: 1rem;
}

.overlayModal {
  z-index: 1000;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.5);
}

.shopContainer {
  display: flex;
}

.shopImage {
  cursor: pointer;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  font-weight: bold;
}

.productItem {
  cursor: pointer;
  background: white;
  color: black;
  border-radius: 0.75rem;
  text-decoration: none;
  -webkit-box-shadow: 0 8px 12px 0 rgba(0, 0, 0, .16);
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, .16);
  margin: 0.7em 0;
  flex-basis: 48%;

  @media (min-width: 768px) {
    flex-basis: 30.4%;
    margin: 1em 0;
  }

  img {
    border-radius: 0.75rem;
    width: 100%;
    min-height: 100px;
  }

  &:last-child {
    margin: 0 !important;
  }
}

.productItemSmall {
  cursor: pointer;
  background: white;
  width: 11em;
  color: black;
  border-radius: 0.75rem;
  text-decoration: none;
  -webkit-box-shadow: 0 8px 12px 0 rgba(0, 0, 0, .16);
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, .16);

  img {
    border-radius: 0.75rem;
    width: 100%;
    min-height: 176px;
  }

  &:last-child {
    margin: 0 !important;
  }
}

.content {
  padding: 0.5rem;
}

.name {
  font-size: 0.9em;
  font-weight: bold;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 36px;
  text-decoration: none;
  overflow: hidden;
}

.price {
  font-size: 0.8em;
  font-weight: bold;
  color: $primary;
  margin-top: 0.25rem;
}

.hidden {
  margin: 0.7em 0;
  flex-basis: 48%;

  @media (min-width: 768px) {
    flex-basis: 30.4%;
    margin: 1em 0;
  }
}

.modalItem {
  width: 13em;
}