:root {
  font-size: 16px;
  font-family: 'Quicksand', sans-serif;
  color: white;
}

html {
  background-image: url('/images/background.png');
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.text-center {
  text-align: center;
}

.swiper-button-next,
.swiper-button-prev {
  color: #ef4492 !important;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

.home-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &:before {
    display: block;
    content: ' ';
    height: 28px;
    width: 28px;
    background-image: url('/images/home.svg');
  }
}

.arrow-up-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &:before {
    display: block;
    content: ' ';
    height: 28px;
    width: 28px;
    background-image: url('/images/arrow-up.svg');
  }
}

.menu-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &:before {
    display: block;
    content: ' ';
    height: 28px;
    width: 28px;
    background-image: url('/images/menu.svg');
  }
}

.close-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &:before {
    display: block;
    content: ' ';
    height: 28px;
    width: 28px;
    background-image: url('/images/close.svg');
  }
}