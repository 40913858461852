.header {
  text-align: center;
  margin-top: 1.5rem;

  h3 {
    margin: 0 auto 20px;
  }
}

img {
  width: 100%;
  border-radius: 8px;
}